<template>
  <div
    v-if="imprintOpen"
    class="absolute right-0 left-0 z-50 top-0 bottom-0 bg-slate-800"
  >
    <dialog open class="card absolute z-50 m-4 sm:m-16" style="min-width: 0px">
      <div class="w-full flex justify-between">
        <h2 class="text-xl font-bold">{{ meta.imprint.title }}</h2>
        <button class="rounded-full cursor-pointer p-2 flex items-center">
          <XMarkIcon
            class="w-8 h-8 text-white m-auto"
            @click="imprintOpen = false"
          />
        </button>
      </div>
      <Markdown :source="imprintContent" class="markdown" />
    </dialog>
  </div>
  <div class="flex w-full md:ml-6 lg:ml-24">
    <AppSidebar
      :nav="nav"
      :current-lang="currentLang"
      @currentLang="switchLanguage($event)"
      class="no-print w-full sm:mt-24 z-40 min-w-full sm:-translate-x-6 md:-translate-x-6 lg:-translate-x-20 xl:-translate-x-32 sm:min-w-min sm:w-1/4 lg:w-1/12 z-100 fixed mb-auto"
    />
    <div
      class="ml-auto mt-40 sm:mt-16 w-full sm:w-3/4 lg:w-3/4 lg:mx-auto px-8 flex flex-col items-center"
    >
      <HeaderSection :header="header" :meta="meta" />
      <main class="flex flex-col w-full">
        <AboutSection :about="about" class="new-page" />

        <QualificationsSection
            :qualifications="references"
            class="new-page"
            id="references"
        />

        <SkillsSection :skills="skills" class="new-page" />
        <QualificationsSection
          :qualifications="qualifications"
          class="new-page"
        />
        <!--
        <section
          id="pricing"
          tabindex="0"
          class="card prose-freelance new-page"
        >
          <h2>{{ pricing.title }}</h2>
          <p>
            {{ pricing.text }}
          </p>

          <PriceCalculator :wages="pricing.range" />
          <small>{{ pricing.description }}</small>
        </section>
        -->
      </main>
    </div>
  </div>
  <FooterSection :meta="meta" @open-imprint="imprintOpen = true" />
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import AppSidebar from "./components/AppSidebar.vue";
//import PriceCalculator from "./components/PriceCalculator.vue";
import AboutSection from "./sections/aboutSection.vue";
import SkillsSection from "./sections/skillsSection.vue";
import QualificationsSection from "./sections/qualificationsSection.vue";
import FooterSection from "./sections/footerSection.vue";
import HeaderSection from "./sections/headerSection.vue";
import Markdown from "vue3-markdown-it";
// General
const currentLang = ref("de");
const switchLanguage = (lang) => {
  currentLang.value = lang;
};
// Icon imports
import { XMarkIcon } from "@heroicons/vue/24/outline";

// Meta
import { metaData } from "./data/meta.json";
const meta = computed(() => {
  return metaData[currentLang.value];
});

// Imprint
import { fetchMarkdown } from "./utils/fetchMarkdown.js";
const imprintContent = ref("");
onMounted(async () => {
  const selectLanguageFile = {
    de: "./imprint-de.md",
    en: "./imprint-en.md",
  };
  imprintContent.value = await fetchMarkdown(
    selectLanguageFile[currentLang.value]
  );
});
const imprintOpen = ref(false);

// Nav
import { navData } from "./data/nav.json";
const nav = computed(() => {
  return navData[currentLang.value];
});

// HEADER
import { headerData } from "./data/header.json";
const header = computed(() => {
  return headerData[currentLang.value];
});

// ABOUT
import { aboutData } from "./data/about.json";
const about = computed(() => {
  return aboutData[currentLang.value];
});

// SKILLS
import { skillsData } from "./data/skills.json";
const skills = computed(() => {
  return skillsData[currentLang.value];
});

// QUALIFICATIONS
import { qualificationsData } from "./data/qualifications.json";
const qualifications = computed(() => {
  return qualificationsData[currentLang.value];
});

import { referencesData } from "./data/references.json";
const references = computed(() => {
  return referencesData[currentLang.value];
});

// PRICING
/*
import { pricingData } from "./data/pricing.json";
const pricing = computed(() => {
  return pricingData[currentLang.value];
});
 */
</script>

<style lang="postcss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
.references {
  @apply flex flex-wrap;
}
.reference-card {
  @apply flex flex-col p-2 sm:p-4 cursor-pointer freelance-text freelance-bg-700  hover:freelance-border-action border border-transparent rounded-lg shadow-lg overflow-hidden mb-8;
}
</style>
