<template>
  <section tabindex="0" :id="id" class="card w-full prose-freelance">
    <h2>{{ qualifications.title }}</h2>
    <p>{{ qualifications.text }}</p>
    <ul class="list-none p-0 flex flex-col">
      <li
        :key="`${asset.title}-${asset.subtitle}`"
        v-for="asset in qualifications.qualifications"
        class="p-0 flex flex-row items-center text-md my-2 freelance-text-light"
      >
        <component
          :is="getIconComponent(asset.icon)"
          class="w-8 h-8 freelance-text-action mr-6 my-3 opacity-50"
        />
        <div class="relative w-full">
          <h3 class="w-4/5 inline-flex text-base leading-5">
            {{ asset.title }}
          </h3>
          <p>{{ asset.subtitle }}</p>
          <small class="sm:absolute right-0 top-0">{{ asset.time }}</small>
        </div>
      </li>
    </ul>
  </section>
</template>

<script setup>
import { defineProps } from "vue";
defineProps({
  qualifications: {
    type: Object,
    required: true,
  },
  id :{
    type: String,
    default: 'qualifications'
  }
});

import {
  MapPinIcon,
  UserCircleIcon,
  MicrophoneIcon,
  AcademicCapIcon,
  TrophyIcon,
  BuildingOfficeIcon,
} from "@heroicons/vue/24/outline";
const iconList = {
  MapPinIcon,
  UserCircleIcon,
  MicrophoneIcon,
  AcademicCapIcon,
  TrophyIcon,
  BuildingOfficeIcon,
};

const getIconComponent = (iconName) => {
  return iconList[iconName] || null;
};
</script>

<style></style>
